// Import your CSS/SCSS files here:

html,
body {
  scroll-behavior: smooth;
  color: #313131;
}

h1,
.h1 {
  font-family: "Plantin-MP", sans-serif !important;
  font-size: responsive 40px 58px;
  line-height: responsive 40px 58px;
  font-weight: 300 !important;
  .italic {
    font-weight: 200 !important;
  }
}

div {
  outline: none !important;
}

h2,
.h2 {
  font-family: "Plantin-MP", sans-serif !important;
  font-size: responsive 25px 48px;
  line-height: responsive 25px 48px;
}

h3,
.h3 {
  font-family: "Plantin-MP", sans-serif !important;
  font-size: responsive 30px 38px;
  line-height: 30px;
}

h4,
.h4 {
  font-family: "Plantin-MP", sans-serif !important;
  font-size: responsive 20px 28px;
  line-height: responsive 20px 28px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 13px;
}

p,
.p {
  font-size: 16px;
  line-height: responsive 24px 26px;
  font-family: "Founders-Grotesk", sans-serif !important;
}

.a {
  font-family: "Plantin-MP", sans-serif !important;
}

.wrapper {
  padding-right: 250px;
  padding-left: 250px;
  max-width: 1670px;
  margin: auto;
  @media (max-width: 1280px) {
    padding-right: 100px;
    padding-left: 100px;
  }
  @media (max-width: 1024px) {
    padding-right: 50px;
    padding-left: 50px;
  }
  @media (max-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .p-bottom {
    display: grid;
    align-items: center;
    margin-bottom: -15px !important;
  }
  .p-bottom:hover {
    color: #0f424c !important;
  }
  .mob-logo {
    display: block !important;
  }

  .big-logo {
    display: none !important;
  }

  .nav-button {
    height: 50px !important;
    width: 100px !important;
  }
  padding-top: 10px;
  height: 70px;
  background-color: #ffffff;
  .nav-links {
    padding-top: 0;
  }

  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 0px !important;
  padding-right: 0px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 40px !important;
    width: auto !important;
  }
  .book-btn {
    height: 45px !important;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 1280px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    .wrapper {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }
  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    background-color: #ffffff;
    height: 65px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 40px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
    .wrapper {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

a {
  text-decoration: none !important;
  p {
    font-family: "AlbertSans-Regular", sans-serif;
    font-size: responsive 14px 17px;
  }
}
a {
  p {
    font-size: responsive 13px 14px;
  }
}

.headroom--top {
  .p-bottom {
    margin-bottom: 0px !important;
    display: grid;
    align-content: end;
  }
  .p-bottom:hover {
    color: #fff !important;
  }
  .mob-logo {
    display: none !important;
  }
  .big-logo {
    display: block !important;
  }
  filter: none;
  height: 90px;
  background-color: #26326100;
  box-shadow: none;
  display: grid;
  .logo {
    height: 55px !important;
    width: 100% !important;
  }

  @media (max-width: 1280px) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    .wrapper {
      padding-left: 60px !important;
      padding-right: 60px !important;
    }
  }

  @media (max-width: 1024px) {
    .wrapper {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    height: 90px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 45px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  padding-left: 0px !important;
  padding-right: 0px !important;

  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}

.hover-underline-dark {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  color: #313131;
  padding-bottom: 2px;
}

.hover-underline-dark:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #313131;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-dark:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

// hover anim light

.hover-underline-light {
  display: inline-block;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  color: #0185db;
  padding-bottom: 2px;
}

.hover-underline-light:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #0185db;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-light:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.shadow-top {
  -webkit-box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
  -moz-box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
  box-shadow: 0px -4px 3px rgba(30, 30, 30, 0.1);
}

summary {
  text-transform: uppercase !important;
  padding-top: 20px;
  @media (max-width: 1024px) {
    padding-top: 10px;
    padding-bottom: 10px !important;
  }
  margin-top: 5px;
  margin-bottom: 5px;
}

details[open] {
  .accord-arrows::before {
    content: "-";
    transition: all 0.5s ease-out;
  }
  padding-bottom: 20px;
  @media (max-width: 1024px) {
    padding-bottom: 10px;
  }
}

details[open] summary {
  padding-bottom: 20px;
  transition: all 0.5s ease-out;
  -webkit-appearance: none;
}

.accord-arrows::before {
  transition: all 0.5s ease-out;
  content: "+";
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999999 !important;

  &__element {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(100%);
    transform-origin: center right;
    background: #0f424c;

    &:first-of-type {
      background: #0f424c;
    }
  }
}
.video-wrapper {
  padding-bottom: 56.25%;
  position: relative;
  display: block;
  width: 100%;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.full-text-col {
  max-width: 900px;
  margin: 0 auto;
  p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  ul {
    margin-bottom: 20px;
    margin-left: 20px;
    li {
      margin-bottom: 10px;
      list-style: initial;
    }
  }
}
