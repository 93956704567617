.fadeUP {
  animation: fadeUP 0.8s ease-in-out;
}

@keyframes fadeUP {
  from {
    opacity: 1;
    transform: translateY(70%);
  }
  to {
    opacity: 1;
  }
}

.navDELAY {
  animation: navdelay 3.5s ease-in-out;
}

@keyframes navdelay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// nav stuff

.nav-fade-1 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
}

.nav-fade-2 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
}
.nav-fade-3 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.4s;
  animation-fill-mode: forwards;
}

.nav-fade-4 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;
}

.nav-fade-5 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.6s;
  animation-fill-mode: forwards;
}
.nav-fade-6 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.6s;
  animation-fill-mode: forwards;
}

.nav-fade-7 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.8s;
  animation-fill-mode: forwards;
}

.nav-fade-8 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 2.9s;
  animation-fill-mode: forwards;
}
.nav-fade-9 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 3s;
  animation-fill-mode: forwards;
}
.nav-fade-10 {
  opacity: 0;
  animation: fadeIn 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeRight {
  animation: fadeInRight 1s ease-in-out;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateY(100%);
    animation-delay: 2s;
  }
  to {
    opacity: 1;
  }
}
